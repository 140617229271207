import React, { useState, useMemo } from 'react';
import { Table, Button, Modal, OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LineGraphChart } from './Charts';
import { EyeIcon, ChartBarIcon, TableIcon } from '@heroicons/react/solid';
import { Pagination } from '#components';

const LIMIT_PER_PAGE = 50;

export const LaboratoryTestsList = ({ laboratoryTests = [], totalItems }) => {
  const { t } = useTranslation();
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showStringHistoryModal, setShowStringHistoryModal] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const pageNumbers = Math.ceil(totalItems / LIMIT_PER_PAGE);
  const pages = Array.from(Array(pageNumbers).keys());

  const onPageChange = (p) => setCurrentPage(p);

  // Group and paginate the tests
  const paginatedTests = useMemo(() => {
    const groupedTests = laboratoryTests.reduce((acc, test) => {
      test.value.forEach((value) => {
        const valueType =
          value.float_value !== null && value.float_value !== undefined ? 'float' : 'string';
        const key = `${test.key}|${valueType}`;
        if (!acc[key] || new Date(value.date) > new Date(acc[key].latestValue.date)) {
          acc[key] = {
            key: test.key,
            type: test.type,
            valueType: valueType,
            latestValue: value,
            document_id: test.document_id,
            originalTest: test,
            code: value.code, // Add the code to the grouped test
          };
        }
      });
      return acc;
    }, {});

    const sortedTests = Object.values(groupedTests).sort((a, b) => {
      const codeA = a.code ? a.code.split('|')[0] : '';
      const codeB = b.code ? b.code.split('|')[0] : '';
      return codeA.localeCompare(codeB);
    });

    const start = currentPage * LIMIT_PER_PAGE;
    const end = start + LIMIT_PER_PAGE;
    return sortedTests.slice(start, end);
  }, [laboratoryTests, currentPage]);

  const getValue = (value) => {
    if (value.float_value !== null && value.float_value !== undefined) {
      return value.float_value;
    }
    if (value.string_value !== null && value.string_value !== undefined) {
      return value.string_value;
    }
    return 'N/A';
  };

  const handleShowHistory = (test) => {
    if (test.type === 'float' && Array.isArray(test.value)) {
      const chartData = {
        key: test.key,
        label: test.key,
        values: test.value
          .map((v) => ({
            date: new Date(v.date),
            value: parseFloat(v.float_value),
          }))
          .sort((a, b) => a.date - b.date)
          .map((v) => ({
            date: v.date.toLocaleDateString(),
            value: v.value,
          })),
      };
      setSelectedTest(chartData);
      setShowHistoryModal(true);
    } else if (test.type === 'string' && Array.isArray(test.value)) {
      const tableData = {
        key: test.key,
        values: test.value
          .map((v) => ({
            date: new Date(v.date),
            value: v.string_value,
          }))
          .sort((a, b) => b.date - a.date) // Sort in descending order
          .map((v) => ({
            date: v.date.toLocaleDateString(),
            value: v.value,
          })),
      };
      setSelectedTest(tableData);
      setShowStringHistoryModal(true);
    }
  };

  const handleCloseHistoryModal = () => {
    setShowHistoryModal(false);
    setShowStringHistoryModal(false);
    setSelectedTest(null);
  };

  if (!laboratoryTests || laboratoryTests.length === 0) {
    return <div>{t('No laboratory tests available')}</div>;
  }

  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t('laboratory-tests.table-head.name')}</th>
            <th>{t('laboratory-tests.table-head.value')}</th>
            <th>{t('laboratory-tests.table-head.date')}</th>
            <th>{t('laboratory-tests.table-head.document')}</th>
            <th>{t('laboratory-tests.table-head.history')}</th>
          </tr>
        </thead>
        <tbody>
          {paginatedTests.map((test) => (
            <tr key={test.key}>
              <td>{test.key}</td>
              <td>{getValue(test.latestValue)}</td>
              <td>
                {test.latestValue.date
                  ? new Date(test.latestValue.date).toLocaleDateString()
                  : 'N/A'}
              </td>
              <td className='text-center'>
                {test.latestValue.document_id ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip className='m-0'>
                        {t('laboratory-tests.view-document-tooltip')}
                      </Tooltip>
                    }
                  >
                    <Link to={`/medical-documents/${test.latestValue.document_id}/edit`}>
                      <EyeIcon className='icon icon-xs text-gray-500' />
                    </Link>
                  </OverlayTrigger>
                ) : (
                  t('laboratory-tests.no-document')
                )}
              </td>
              <td className='text-center'>
                {(test.type === 'float' || test.type === 'string') && (
                  <OverlayTrigger
                    overlay={
                      <Tooltip className='m-0'>
                        {t('laboratory-tests.view-history-tooltip')}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant='link'
                      className='p-0'
                      onClick={() => handleShowHistory(test.originalTest)}
                    >
                      {test.type === 'float' ? (
                        <ChartBarIcon className='icon icon-xs text-gray-500' />
                      ) : (
                        <TableIcon className='icon icon-xs text-gray-500' />
                      )}
                    </Button>
                  </OverlayTrigger>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Card.Footer className='px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between'>
        <Pagination
          pages={pages}
          limit={LIMIT_PER_PAGE}
          pageNumbers={pageNumbers}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      </Card.Footer>

      <Modal show={showHistoryModal} onHide={handleCloseHistoryModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('laboratory-tests.history-modal-title', { testName: selectedTest?.key })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{selectedTest && <LineGraphChart data={[selectedTest]} />}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseHistoryModal}>
            {t('laboratory-tests.close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showStringHistoryModal} onHide={handleCloseHistoryModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('laboratory-tests.history-modal-title', { testName: selectedTest?.key })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTest && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{t('laboratory-tests.history-modal-date')}</th>
                  <th>{t('laboratory-tests.history-modal-value')}</th>
                </tr>
              </thead>
              <tbody>
                {selectedTest.values.map((item, index) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseHistoryModal}>
            {t('laboratory-tests.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
