import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function Headers() {
  const { t } = useTranslation();
  const { lang } = useParams();

  const currentLang = lang || 'pl';
  const baseUrl = 'https://www.medify.me';

  return (
    <Helmet>
      <html lang={currentLang} />
      <title>{t('headers.title')}</title>
      <meta name='description' content={t('headers.description')} />
      <link rel='canonical' href={`${baseUrl}/${currentLang}`} />
      <link rel='alternate' hrefLang='x-default' href={`${baseUrl}/pl`} />
      <link rel='alternate' hrefLang='en' href={`${baseUrl}/en`} />
      <link rel='alternate' hrefLang='pl' href={`${baseUrl}/pl`} />
    </Helmet>
  );
}

export default Headers;
